import Mock from 'mockjs'
import home from './json/Home.json'
import product from './json/Product/Product.json'
import tabs from './json/Product/TitleTabs.json'
import DistributionIntroduce from './json/Product/DistributionIntroduce.json'
import DistributionTeach from './json/Product/DistributionTeach.json'
import GoodsNumberIntroduce from './json/Product/GoodsNumberIntroduce.json'
import GoodsNumberTeach from './json/Product/GoodsNumberTeach.json'
import DistributionProblem from './json/Product/DistributionProblem.json'
import GoodsNumberProblem from './json/Product/GoodsNumberProblem.json'
import DistributionDownload from './json/Product/DistributionDownload.json'
import GoodsNumbersDownload from './json/Product/GoodsNumbersDownload.json'

import OrderTabs from './json/Order/tabs.json'
import CaigouIntroduce from './json/Order/caigouIntroduce.json'
import FahuoIntroduce from './json/Order/fahuoIntroduce.json'
import CaigouTeach from './json/Order/caigouTeach.json'
import FahuoTeach from './json/Order/fahuoTeach.json'
import CaigouProblem from './json/Order/caigouProblem.json'
import FahuoProblem from './json/Order/fahuoProblem.json'
import CaigouDownload from './json/Order/caigouDownload.json'
import FahuoDownload from './json/Order/fahuoDownload.json'

import DataTabs from './json/Data/tabs.json'
import DataIntroduce from './json/Data/dataIntroduce.json'
import DataTeach from './json/Data/dataTeach.json'
import DataProblem from './json/Data/dataProblem.json'
import DataDownload from './json/Data/dataDownload.json'

import FinacneTabs from './json/Finance/tabs.json'
import FinanceIntroduce from './json/Finance/financeIntroduce.json'
import FinanceTeach from './json/Finance/financeTeach.json'
import FinanceProblem from './json/Finance/financeProblem.json'
import FinacneDownload from './json/Finance/financeDownload.json'

import AutoTabs from './json/Auto/tabs.json'
import AutoIntroduce from './json/Auto/autoIntroduce.json'
import AutoTeach from './json/Auto/autoTeach.json'
import AutoProblem from './json/Auto/autoProblem.json'
import AutoDownload from './json/Auto/autoDownload.json'

import SetTabs from './json/Set/tabs.json'
import SetIntroduce from './json/Set/setIntroduce.json'
import SetTeach from './json/Set/setTeach.json'
import SetProblem from './json/Set/setProblem.json'
import SetDownload from './json/Set/setDownload.json'

import Yzm from './json/Yzm/yzm.json'

Mock.mock('/mock/yzm', {
    code: 200,
    data: Yzm
})

Mock.mock('/mock/setProblem', {
    code: 200,
    data: SetProblem
})

Mock.mock('/mock/setDownload', {
    code: 200,
    data: SetDownload
})

Mock.mock('/mock/setTeach', {
    code: 200,
    data: SetTeach
})

Mock.mock('/mock/setIntroduce', {
    code: 200,
    data: SetIntroduce
})

Mock.mock('/mock/setTabs', {
    code: 200,
    data: SetTabs
})

Mock.mock('/mock/autoDownload', {
    code: 200,
    data: AutoDownload
})

Mock.mock('/mock/autoProblem', {
    code: 200,
    data: AutoProblem
})

Mock.mock('/mock/autoTeach', {
    code: 200,
    data: AutoTeach
})

Mock.mock('/mock/autoIntroduce', {
    code: 200,
    data: AutoIntroduce
})

Mock.mock('/mock/autoTabs', {
    code: 200,
    data: AutoTabs
})

Mock.mock('/mock/finacneDownload', {
    code: 200,
    data: FinacneDownload
})

Mock.mock('/mock/financeProblem', {
    code: 200,
    data: FinanceProblem
})

Mock.mock('/mock/financeTeach', {
    code: 200,
    data: FinanceTeach
})

Mock.mock('/mock/financeIntroduce', {
    code: 200,
    data: FinanceIntroduce
})

Mock.mock('/mock/finacneTabs', {
    code: 200,
    data: FinacneTabs
})

Mock.mock('/mock/dataDownload', {
    code: 200,
    data: DataDownload
})

Mock.mock('/mock/dataProblem', {
    code: 200,
    data: DataProblem
})

Mock.mock('/mock/dataTeach', {
    code: 200,
    data: DataTeach
})

Mock.mock('/mock/dataIntroduce', {
    code: 200,
    data: DataIntroduce
})

Mock.mock('/mock/dataTabs', {
    code: 200,
    data: DataTabs
})

Mock.mock('/mock/caigouDownload', {
    code: 200,
    data: CaigouDownload
})

Mock.mock('/mock/fahuoDownload', {
    code: 200,
    data: FahuoDownload
})

Mock.mock('/mock/fahuoProblem', {
    code: 200,
    data: FahuoProblem
})

Mock.mock('/mock/caigouProblem', {
    code: 200,
    data: CaigouProblem
})

Mock.mock('/mock/fahuoTeach', {
    code: 200,
    data: FahuoTeach
})

Mock.mock('/mock/caigouTeach', {
    code: 200,
    data: CaigouTeach
})

Mock.mock('/mock/fahuoIntroduce', {
    code: 200,
    data: FahuoIntroduce
})

Mock.mock('/mock/caigouIntroduce', {
    code: 200,
    data: CaigouIntroduce
})

Mock.mock('/mock/orderTabs', {
    code: 200,
    data: OrderTabs
})

Mock.mock('/mock/home',{
    code: 200,
    data: home
})

Mock.mock('/mock/product', {
    code: 200,
    data: product
})

Mock.mock('/mock/tabs', {
    code: 200,
    data: tabs
})

Mock.mock('/mock/distributionIntroduce', {
    code: 200,
    data: DistributionIntroduce,
})

Mock.mock('/mock/goodsNumberIntroduce', {
    code: 200,
    data: GoodsNumberIntroduce
})

Mock.mock('/mock/distributionTeach', {
    code: 200,
    data: DistributionTeach,
})

Mock.mock('/mock/goodsNumberTeach', {
    code: 200,
    data: GoodsNumberTeach,
})

Mock.mock('/mock/distributionProblem', {
    code: 200,
    data: DistributionProblem,
})

Mock.mock('/mock/goodsNumberProblem', {
    code: 200,
    data: GoodsNumberProblem,
})

Mock.mock('/mock/distributionDownload', {
    code: 200,
    data: DistributionDownload,
})

Mock.mock('/mock/goodsNumbersDownload', {
    code: 200,
    data: GoodsNumbersDownload,
})