<template>
    <div id="app">
        <Header></Header>
        <router-view></router-view>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
export default {
    name: 'App',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            showLogin: true,
            form: {
                tel: '',
                yzm: '',
            },
            showTag: true
        }
    },
    methods: {
    },
}
</script>

<style lang="less">
    
</style>
