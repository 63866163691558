<template>
	<div class="header">
		<div class="login">
			<span>欢迎使用IKONGE</span>
			<!-- <a href="#" v-if="!res" @click="dialogFormVisible = true;showLogin=false">快速登录</a>
			<span v-else>欢迎, {{res}} <a href="#" @click="logout">注销</a> </span> -->
		</div>

		<div class="main wapper">
			<h2 class="logo">
				<a href="javaScript:;" @click="toHome">
					<img src="./images/logo.png" alt="" />
				</a>
			</h2>
			<div class="nav">
				<ul>
					<li v-for="(item, index) in navList" :key="index" @click="toPage(index)"
						:class="[currentIndex===index?'active': '']">{{item}}</li>
				</ul>
			</div>
		</div>
		

		<el-dialog title="登录" :visible.sync="dialogFormVisible" @close="closeDialog">
			<el-form :model="loginForm" :rules="rules" ref="ruleForm">
				<el-form-item label-width="20px" prop="tel">
					<el-input clearable placeholder="请输入手机" prefix-icon="el-icon-mobile" v-model="loginForm.tel"></el-input>
				</el-form-item>

				<el-form-item label-width="20px" prop="yzm">
					<el-input class="yzm" :style="{width:(isSendYzm?'162px':'182px')}"
						 placeholder="请输入验证码" prefix-icon="el-icon-message" 
						 v-model="loginForm.yzm"></el-input>
					<el-button class="yzmBtn" ref="yzmBtnRef" 
						:class="[isSendYzm?'sendyzm': '']"
					 :loading="isloading" @click="getYzm">获取验证码</el-button>
				</el-form-item>

				<el-button class="loginBtn" type="primary"  @click="login('ruleForm')">登录</el-button>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>

export default {
	name: "Header",

	data() {
		let checkTel = (rule, value, callback) => {
			let regTel = /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/
			if(!value) {
				return callback('请输入电话号码')
			}else if(!regTel.test(value)) {
				return callback('请输入有效的电话号码')
			}else {
				callback()
			}
		};

		let checkYzm = (rule, value, callback) => {
			if(!value) {
				return callback('请输入验证码')
			}else if(value.length < 4) {
				return callback('验证码长度不对')
			}else if (this.yzm !== value) {
				return callback("请输入正确的验证码")
			}else if(this.yzm === value){
				callback()
			}
		}

		return {
			navList: ['首页', '产品介绍', '联系我们'],
			currentIndex: 0,

			dialogFormVisible: false,
			showLogin: true,
			loginForm: {
				tel: '',
				yzm: ''
			},

			res: "",

			rules: {
				tel: [
					{ validator: checkTel, trigger: 'blur' }
          		],
				yzm: [
					{ validator: checkYzm, trigger: 'blur' }
				]
			},

			yzm: '',
			isloading: false,
			intervalId: 0,
			isSendYzm: false,
		};
	},

	watch: {
		$route: {
			immediate: true,
			handler(newValue, oldValue) {
				if(newValue.path.lastIndexOf('/Product') !== -1) {
					this.currentIndex = 1
				}else if (newValue.path.lastIndexOf("/Home") !== -1) {
					this.currentIndex = 0
				}else if(newValue.path.lastIndexOf('/OnLineMessage') !== -1) {
					this.currentIndex = 2
				}
			},
			deep:true
		}
	},

	methods: {
		toHome() {
			this.$router.replace('/Home')
		},
		toPage(index) {
			this.currentIndex = index
			if(index === 0) {
				this.$router.push('/Home')
			}else if(index === 1) {
				this.$router.push('/Product')
			}else if(index === 2) {
				this.$router.push('/OnLineMessage')
			}
		},

	 	async getYzm() {
			this.isloading = true;
			const {code, data} = await this.$http.get('/yzm')
			if(code === 200) {
				this.isSendYzm = true;
				this.yzm = data.code
				let sec = 60
				this.intervalId = window.setInterval(() => {
					this.isloading = false;	
					sec--
					this.$refs.yzmBtnRef.$el.textContent = `${sec}s 后重新获取`
					this.$refs.yzmBtnRef.$el.disabled = true
					this.$refs.yzmBtnRef.backgroundColor = "#ccc"
					if(sec === 0) {
						window.clearInterval(this.intervalId)
						this.isSendYzm = false
						this.$refs.yzmBtnRef.$el.textContent = `获取验证码`
						this.$refs.yzmBtnRef.$el.disabled = false
					}
				},1000)
			}else {
				alert('网络有问题')
			}
		},

		login(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.dialogFormVisible = false
					this.showLogin = false
					// window.clearInterval(this.intervalId)
					this.res = this.loginForm.tel
					localStorage.setItem('kgToken', this.res)
          		} else {
					console.log('error submit!!');
            		return false;
          		}
        	});

			
		},

		logout() {
			this.dialogFormVisible = false;
			this.showLogin=true;
			this.res = ""
			localStorage.removeItem('kgToken')
		},

		closeDialog() {
			window.clearInterval(this.intervalId)
			this.isSendYzm = false
			this.isloading = false
			this.$refs.yzmBtnRef.$el.textContent = `获取验证码`
			this.$refs.yzmBtnRef.$el.disabled = false

			this.dialogFormVisible = false
			// this.showLogin = true

			this.loginForm = {
				tel: '',
				yzm: ''
			}

			this.$refs.ruleForm.clearValidate();
		}
	},

	mounted() {
		this.res = localStorage.getItem('kgToken') || '';
	}
};
</script>

<style lang="less" scoped>
.header {
	.login {
		width: 100%;
		background-color: rgb(244, 244, 244);
		height: 40px;
		line-height: 40px;
		color: #1890ff;
		display: flex;
		flex-direction: row-reverse;
		span {
			margin-right: 15px;
		}
		a {
			color: #1890ff;
			cursor: pointer;
			line-height: 40px;
			margin-right: 15px;
		}
	}

	.main {
		height: 150px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.logo {
			width: 110px;
			height: 90px;
			a {
				display: block;
				img {
					height: 90px;
					animation: 20s linear 0s infinite normal none running App-logo-spin;
					pointer-events: none;
				}
			}
		}
		.nav { 
			ul {
				display: flex;
				justify-content: space-between;
				align-items: center;
				// width: 32%;
				li {
					cursor: pointer;
					margin-right: 15px;
					&:last-child {
						margin-right: 0;
					}
					width: 110px;
					height: 40px;
					padding: 7px 20px;
					text-align: center;
					border-radius: 40px;
					letter-spacing: 1px;
					transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
					&.active {
						background-color: #40a9ff;
						border-color: #40a9ff;
						color: #fff;
						text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
						// box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
					}
					&:hover {
						background-color: #40a9ff;
						border-color: #40a9ff;
						color: #fff;
						text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
						box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
					}
				}
			}
			
		}
	}

	.sendyzm {
		background: #f5f5f5;
		padding-left: 15px;
		width: 122px !important;
	}

	/deep/ .el-input {
		width: 290px;
		height: 40px;
	}

	/deep/ .el-input.yzm {
		width: 182px;
		height: 40px;
		margin-right: 5px;
	}

	.el-dialog__body {
		padding: 10px !important;
	}

	/deep/ .el-button.yzmBtn {
		width: 102px;
	}

	/deep/ .el-dialog {
		width: 350px;
		height: 250px;
	}

	/deep/ .el-dialog__body {
		padding: 10px !important;
	}

	.loginBtn{
		margin-left: 20px;
		width: 290px;
	}

}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;

		pointer-events: "none";
		height: 90px;
		margin-left: 20px;
	}
}
@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

</style>
