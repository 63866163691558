const Home = () => import(/* webpackChunkName: "Home" */'../pages/Home');
const OnLineMessage = () => import(/* webpackChunkName: "OnLineMessage" */'../pages/OnLineMessage');
const Product = () => import(/* webpackChunkName: "Product" */'../pages/Product');
const Goods = () => import(/* webpackChunkName: "Goods" */'../pages/Product/pages/Goods');
const Order = () => import(/* webpackChunkName: "Order" */'../pages/Product/pages/Order');
const Data = () => import(/* webpackChunkName: "Data" */'../pages/Product/pages/Data');
const Finance = () => import(/* webpackChunkName: "Finance" */'../pages/Product/pages/Finance');
const Auto = () => import(/* webpackChunkName: "Auto" */'../pages/Product/pages/Auto');
const Set = () => import(/* webpackChunkName: "Set" */'../pages/Product/pages/Set');
const NotFound = () => import(/* webpackChunkName: "NotFound" */'../components/NotFound');

const routes = [
    {
        path: '/',
        redirect: '/Home'
    },
    {
        path: '/Home',
        component: Home,
    },
    {
        path: '/OnLineMessage',
        component: OnLineMessage,
    },
    {
        path: '/Product',
        component: Product,
        children: [
            {
                path: 'Goods',
                component: Goods
            },
            {
                path: 'Order',
                component: Order
            },
            {
                path: 'Data',
                component: Data
            },
            {
                path: 'Finance',
                component: Finance
            },
            {
                path: 'Auto',
                component: Auto
            },
            {
                path: 'Set',
                component: Set
            }
        ]
    },
    {
        path: '*',
        component: NotFound
    }
];

export default routes;