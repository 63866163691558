import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

import routes from '../routes'

const router = new VueRouter({
    routes,

    mode: 'history',

    base: '/',

    linkActiveClass: 'active'
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err);
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err)
};

export default router;
