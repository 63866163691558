import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
import 'swiper/swiper-bundle.min.css'

import router from './router'

import "./mock/mock"

import $http from './http'
Vue.prototype.$http = $http

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import { Dialog, Form, FormItem, Input, Button } from 'element-ui';
Vue.component(Button.name, Button);
Vue.component(Dialog.name, Dialog);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(Input.name, Input);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
