<template>
    <div class="Footer">
        <div class="wapper footer">
            <div class="top">
                <div class="left">
                    <div class="email">ikonge@sohu.com</div>
                    <div class="businessAddress">www.ikonge.com</div>
                    <div class="address">厦门市软件园三期诚毅北大街2号1701-D单元</div>
                </div>
                <div class="erweima">
                    <img src="./images/logo.png" alt="">
                </div>
            </div>

            <div class="bottom">
                <div class="businessMsg">厦门空格网络科技有限公司 © 2020-2025 </div>
                <div class="right">
                    <ul>
                        <li><a href="https://beian.miit.gov.cn" target="_blank">闽ICP备2020019116号-3</a></li>
                        <li><a href="https://beian.miit.gov.cn" target="_blank">闽ICP备2020019116号-2</a></li>
                        <li><a href="https://beian.miit.gov.cn" target="_blank">闽ICP备2020019116号-6</a></li>
                    </ul>
                    <span>IKONGE</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
.Footer {
    background-color:  rgb(39, 43, 46);
    color: white;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    .footer {
        padding-top: 20px;
        .top {
            width: 80%;
            padding-bottom: 20px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #ccc;
            .erweima {
                img {
                    width: 70px;
                    height: 70px
                }
            }
        }
        .bottom {
            width: 80%;
            margin: 0 auto;
            padding: 20px 0;
            .businessMsg {
                font-size: 14px;
            }
            .right {
                display: flex;
                justify-content: space-between;
                ul{
                    display: flex;
                    li{
                        margin-right: 5px;
                        font-size: 14px;
                    }
                }
            }
        }
        a {
            color: white;
        }
    }
    
}
</style>