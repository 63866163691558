import axios from  'axios';


const axiosIns = axios.create({
    baseURL: '/mock',
    timeout:10000,
});

axiosIns.interceptors.request.use(function (config) {
    return config;
});

axiosIns.interceptors.response.use(function (response) {
    return response.data;
},function (error) {
    return Promise.reject(error);
});

export default axiosIns;